// Custom Theming for Angular Material
@use '~@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$megapos-hq-front-primary: mat.define-palette(mat.$indigo-palette);
$megapos-hq-front-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$megapos-hq-front-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$megapos-hq-front-theme: mat.define-light-theme(
    (
        color: (
            primary: $megapos-hq-front-primary,
            accent: $megapos-hq-front-accent,
            warn: $megapos-hq-front-warn,
        ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($megapos-hq-front-theme);

/* You can add global styles to this file, and also import other style files */
@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeflex/primeflex.scss';
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/prismjs/themes/prism-coy.css';
@import '../node_modules/@fullcalendar/core/main.min.css';
@import '../node_modules/@fullcalendar/daygrid/main.min.css';
@import '../node_modules/@fullcalendar/timegrid/main.min.css';
// @import 'assets/demo/flags/flags.css';

* {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}
.elli {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "~ngx-bootstrap/datepicker/bs-datepicker";
